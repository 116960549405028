import React from "react";
import { Calendar } from "iconoir-react";
import { navigate } from "@reach/router";

// formulário
const handleSubmit = (event) => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => navigate("/contato/obrigada/"))
    .catch((error) => alert(error));
};

let paginaValue = "";
if (typeof window !== 'undefined') {
  paginaValue = window.location.href;
}


const ScheduleSection = () => {

  return (
    <section class="schedule-section">
      <div className="container">
        <h2><Calendar />&nbsp;Quer saber mais? Agende uma visita!</h2>
        <form name="novo-lead" method="POST" onSubmit={handleSubmit} data-netlify="true">
          <input type="hidden" name="form-name" value="novo-lead" />
          <label for="nome" style={{ display: "none" }}>
            Nome:
          </label>
          <input type="text" name="nome" placeholder="Digite seu nome" required />
          <label for="whatsapp" style={{ display: "none" }}>
            WhatsApp:
          </label>
          <input type="tel" name="whatsapp" placeholder="Digite seu WhatsApp" pattern="[0-9]{2}[0-9]{5}[0-9]{4}" title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" required />
          <input type="hidden" name="pagina" value={paginaValue} required />
          <button type="submit" class="btn-secondary">Enviar →</button>
        </form>
        <p class="text-align-center margin-top-24">Ao clicar no botão enviar, você concorda com os <a
          href="https://crisazevedoimoveis.com.br/termos-de-uso/">Termos de Uso</a> e <a href="https://crisazevedoimoveis.com.br/politica-de-privacidade/">Política de Privacidade</a> e confirma ter mais de 18 anos.</p>
      </div>
    </section>
  );
};

export default ScheduleSection;