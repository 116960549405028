import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import WhatsappFloatingButton from "../components/WhatsappFloatingButton";
import ScheduleCard from "../components/ScheduleCard";
import ScheduleSection from "../components/ScheduleSection";
import { Svg3DRectCornerToCorner, Bed, Car, Bathroom, Building, Calendar } from 'iconoir-react';
import { navigate } from "@reach/router";


// formulário
const handleSubmit = (event) => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => navigate("/contato/obrigada/"))
    .catch((error) => alert(error));
};

let paginaValue = "";
if (typeof window !== 'undefined') {
  paginaValue = window.location.href;
}


// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  address,
  status,
  price,
  condominium,
  iptu,
  footage,
  bedrooms,
  parkings,
  bathrooms,
  floor,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  // função para destacar as palavras específicas nas tags <span>
  React.useEffect(() => {
    const spans = document.getElementsByTagName("span");
    for (let i = 0; i < spans.length; i++) {
      if (spans[i].textContent.indexOf("Na planta") !== -1) {
        spans[i].classList.add("tag-warning");
      }
      if (spans[i].textContent.indexOf("Em construção") !== -1) {
        spans[i].classList.add("tag-warning2");
      }
      if (spans[i].textContent.indexOf("Pronto pra morar") !== -1) {
        spans[i].classList.add("tag-success");
      }
    }
  }, []);

  React.useEffect(() => {
    var scheduleCard = document.querySelector('.schedule-card');

    window.addEventListener('scroll', function () {
      var y = window.scrollY;
      if (y > 0) {
        scheduleCard.classList.add('fix');
      } else {
        scheduleCard.classList.remove('fix');
      }
    });
  }, []);

  return (
    <div>
      <section className="section">
        {helmet || ""}
        <div className="container content content-post">
          <div className="columns">
            <div className="column">
              <div className="columns">
                <div className="column">
                  <span className="tag">{status}</span>
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{ marginTop: "24px" }}>{title}</h1>
                  <p style={{ marginBottom: "8px" }}>{address}</p>
                  {tags && tags.length ? (
                    <div style={{ marginTop: `0rem` }}>
                      <ul className="taglist" style={{ marginTop: "0" }}>
                        {tags.map((tag) => (
                          <li key={tag + `tag`}>
                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <p style={{ marginBottom: "8px" }}>A partir de:</p>
                  <h2 style={{ marginTop: "0" }}>R$ {price}</h2>
                  {condominium && (
                    <p style={{ marginBottom: "4px" }}>Condomínio R$ {condominium}</p>
                  )}

                  {iptu && (
                    <p style={{ marginBottom: "48px" }}>IPTU R$ {iptu}</p>
                  )}

                  <div className="columns main-features">
                    {footage && (
                      <div className="column has-text-centered">
                        <Svg3DRectCornerToCorner />
                        <p>{footage} m²</p>
                      </div>
                    )}

                    <div className="column has-text-centered">
                      <Bed />
                      <p>{bedrooms} quartos</p>
                    </div>
                    {parkings !== 0 && (
                      <div className="column has-text-centered">
                        <Car />
                        <p>{parkings} vagas</p>
                      </div>
                    )}
                    <div className="column has-text-centered">
                      <Bathroom />
                      <p>{bathrooms} banheiros</p>
                    </div>
                    {floor !== 0 && (
                      <div className="column has-text-centered">
                        <Building />
                        <p>{floor}º andar</p>
                      </div>
                    )}
                  </div>

                  <PostContent content={content} />
                </div>
                <div className="column">
                  <section class="schedule-card is-justify-content-center">
                    <div className="container">
                      <h2 className="is-justify-content-center"><Calendar />&nbsp;Quer saber mais? Agende uma visita!</h2>

                      <form name="novo-lead-imovel" method="POST" onSubmit={handleSubmit} data-netlify="true">
                        <input type="hidden" name="form-name" value="novo-lead-imovel" />
                        <label for="nome" style={{ display: "none" }}>
                          Nome:
                        </label>
                        <input type="text" name="nome" placeholder="Digite seu nome" required />
                        <label for="whatsapp" style={{ display: "none" }}>
                          WhatsApp:
                        </label>
                        <input type="tel" name="whatsapp" placeholder="Digite seu WhatsApp" pattern="[0-9]{2}[0-9]{5}[0-9]{4}" title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" required />
                        <input type="hidden" name="imovel-interesse" value={JSON.stringify(title)} required />
                        <input type="hidden" name="pagina" value={paginaValue} required /> 
                        <button type="submit" class="btn-secondary">Enviar →</button>
                      </form>

                      {/* <form name="Novo Lead – Início" method="POST" data-netlify="true">
          <input type="text" name="Nome:" placeholder="Digite seu nome" required />
          <input type="tel" name="Whatsapp:" placeholder="Digite seu WhatsApp" required
            pattern="[0-9]{2}[0-9]{5}[0-9]{4}"
            title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" />
          <button type="submit" class="btn-secondary">Enviar →</button>
        </form> */}
                      <p class="text-align-center margin-top-24">Ao clicar no botão enviar, você concorda com os <a
                        href="/termos-de-uso/">Termos de Uso</a> e <a href="/politica-de-privacidade/">Política de Privacidade</a> e confirma ter mais de 18 anos.</p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhatsappFloatingButton />
      <section class="schedule-section">
        <div className="container">
          <h2><Calendar />&nbsp;Quer saber mais? Agende uma visita!</h2>
          <form name="novo-lead" method="POST" onSubmit={handleSubmit} data-netlify="true">
            <input type="hidden" name="form-name" value="novo-lead" />
            <label for="nome" style={{ display: "none" }}>
              Nome:
            </label>
            <input type="text" name="nome" placeholder="Digite seu nome" required />
            <label for="whatsapp" style={{ display: "none" }}>
              WhatsApp:
            </label>
            <input type="tel" name="whatsapp" placeholder="Digite seu WhatsApp" pattern="[0-9]{2}[0-9]{5}[0-9]{4}" title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" required />
            <input type="hidden" name="imovel-interesse" value={JSON.stringify(title)} required />
            <input type="hidden" name="pagina" value={paginaValue} required />
            <button type="submit" class="btn-secondary">Enviar →</button>
          </form>
          <p class="text-align-center margin-top-24">Ao clicar no botão enviar, você concorda com os <a
            href="https://crisazevedoimoveis.com.br/termos-de-uso/">Termos de Uso</a> e <a href="https://crisazevedoimoveis.com.br/politica-de-privacidade/">Política de Privacidade</a> e confirma ter mais de 18 anos.</p>
        </div>
      </section>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>

      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        price={post.frontmatter.price}
        condominium={post.frontmatter.condominium}
        iptu={post.frontmatter.iptu}
        helmet={
          <Helmet titleTemplate="%s | Imóvel">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="price"
              content={`${post.frontmatter.price}`}
            />
          </Helmet>
        }
        address={post.frontmatter.address}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        status={post.frontmatter.status}
        footage={post.frontmatter.footage}
        bedrooms={post.frontmatter.bedrooms}
        parkings={post.frontmatter.parkings}
        bathrooms={post.frontmatter.bathrooms}
        floor={post.frontmatter.floor}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        status
        title
        address
        tags
        price
        condominium
        iptu
        footage
        bedrooms
        bathrooms
        parkings
        floor
      }
    }
  }
`;
