import { Link } from "gatsby";
import React from "react";

const WhatsappFloatingButton = () => {

  return (
    <Link href="https://wa.me/5521991664371/" className="whatsapp-floating-button scale" target="_blank">
        <img src="https://crisazevedoimoveis.com.br/img/whatsapp-floating-button.png" alt="Botão flutuante do WhatsApp" width="206" height="56" />
    </Link>
  );
};

export default WhatsappFloatingButton;