import React from "react";

const ScheduleSection = () => {

  return (
    <section class="schedule-card">
      <div className="container">
        <h2>Quer saber mais? Agende uma visita!</h2>

        <form name="Novo Lead - Imóvel" method="POST" data-netlify="true" action="/contato/obrigada/">
        <input type="hidden" name="Novo Lead - Imóvel" value="Novo Lead - Imóvel" />  
        <label for="nome" style={{display: "none"}}>
            Nome:
          </label>
          <input type="text" name="nome" placeholder="Digite seu nome" required />
          <label for="whatsapp" style={{display: "none"}}>
            WhatsApp:
          </label>
          <input type="tel" name="whatsapp" placeholder="Digite seu WhatsApp" pattern="[0-9]{2}[0-9]{5}[0-9]{4}" title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" required />
          <button type="submit" class="btn-secondary">Enviar →</button>
        </form>

        {/* <form name="Novo Lead – Início" method="POST" data-netlify="true">
          <input type="text" name="Nome:" placeholder="Digite seu nome" required />
          <input type="tel" name="Whatsapp:" placeholder="Digite seu WhatsApp" required
            pattern="[0-9]{2}[0-9]{5}[0-9]{4}"
            title="Digite o telefone com DDD e 9 dígitos (ex: 21999990000)" />
          <button type="submit" class="btn-secondary">Enviar →</button>
        </form> */}
        <p class="text-align-center margin-top-24">Ao clicar no botão enviar, você concorda com os <a
          href="https://crisazevedoimoveis.com.br/termos-de-uso/">Termos de Uso</a> e <a href="https://crisazevedoimoveis.com.br/politica-de-privacidade/">Política de Privacidade</a> e confirma ter mais de 18 anos.</p>
      </div>
    </section>
  );
};

export default ScheduleSection;